<template>
  <div class="list">
    <van-swipe
      class="my-swipe"
      v-if="swiperList.length"
      :autoplay="3000"
      indicator-color="white"
      :stop-propagation="false"
    >
      <van-swipe-item v-for="(item, index) of swiperList" :key="index">
        <img
          style="width: 100%; height: 100%; object-fit: cover"
          :src="item.image"
          @click="goDetail1(item)"
          alt=""
        />
      </van-swipe-item>
    </van-swipe>
    <van-tabs
      class="tabs"
      @change="tabChange"
      :swipeable="true"
      :animated="true"
      v-model="active"
      v-if="subCateList.length"
      sticky
    >
      <van-tab
        :title="item.cate_name"
        v-for="(item, index) of subCateList"
        :key="index"
      >
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          :immediate-check="false"
          @load="getChild"
        >
          <div class="sub-list" v-if="articleList.length">
            <div
              class="item"
              v-for="(item, index) of articleList"
              :key="index"
              @click="goDetail(item)"
            >
              <div class="title" v-text="item.article_title"></div>
              <div class="img-wrap">
                <img class="img" v-lazy="item.image" alt="" />
              </div>
            </div>
          </div>
          <div class="no-data" v-else>暂无数据...</div>
        </van-list>
      </van-tab>
    </van-tabs>
    <div class="video-wrap" v-show="showVideo">
      <video
        ref="videoIds"
        class="video-source"
        id="video"
        loop
        controls
        preload="auto"
        x-webkit-airplay="true"
        x5-video-player-fullscreen="false"
        x5-video-orientation="portraint"
        x5-video-player-type="h5-page"
        playsinline="true"
        :src="videoUrl"
        @pause="endVideo"
      >
        您的浏览器可能不支持视频播放
      </video>
      <div class="back">
        <van-icon size="30" name="arrow-left" @click="endVideo" color="#fff" />
      </div>
    </div>
  </div>
</template>

<script>
import { getArticles, getArticlesChildren } from "@api";
export default {
  name: "Article",
  data() {
    return {
      list: [],
      swiperList: [],
      active: 0,
      subCateList: [],
      articleList: [],
      showVideo: false,
      videoUrl: "",
      loading: false,
      finished: false,
      page: 1,
    };
  },
  mounted() {
    this.active = this.$route.query.active ? this.$route.query.active : 0;
    this.getList();
  },
  methods: {
    endVideo() {
      const oDiv = this.$refs.videoIds;
      oDiv.pause();
      this.showVideo = false;
    },
    onLoad() {
      console.log("onload======");
      this.page++;
    },
    getList() {
      let id = this.$route.query.id;
      getArticles(id, this.page).then(({ data }) => {
        if (data.cate && data.cate.image && data.cate.image.length) {
          this.swiperList = data.cate.image;
        }
        if (data.child && data.child.length) {
          this.subCateList = data.child;
          this.getChild();
        }
      });
    },

    getChild() {
      let id = this.subCateList[this.active].id;
      this.loading = true;
      getArticlesChildren(id, this.page).then(({ data }) => {
        this.articleList =
          this.page === 1 ? data.data : this.articleList.concat(data.data);
        this.loading = false;
        this.page++;
        if (!data.data || !data.data.length) {
          this.finished = true;
        }
      });
    },

    tabChange() {
      this.finished = false;
      this.page = 1;
      this.getChild();
    },

    goDetail(item) {
      if (item.links) {
        let type = this.getType(item.links);

        if (type.includes("mp4")) {
          //视频
          this.videoUrl = item.links;
          this.showVideo = true;
          this.autoPlayAudio();
        } else {
          //图片
          window.location.href = item.links;
        }
        return;
      }
      this.$router.push({
        path: "/articleDetail?id=" + item.article_id,
      });
    },

    goDetail1(item) {
      if (item.url) {
        let type = this.getType(item.url);
        if (type.includes("mp4")) {
          //视频
          this.videoUrl = item.url;
          this.showVideo = true;
          this.autoPlayAudio();
        } else {
          //图片
          window.location.href = item.url;
        }
        return;
      }
      this.$router.push({
        path: "/articleDetail?id=" + item.article_id,
      });
    },
    autoPlayAudio() {
      const video = this.$refs.videoIds;
      if (typeof WeixinJSBridge !== "undefined") {
        WeixinJSBridge.invoke("getNetworkType", {}, function (e) {
          // 利用该方法进行自动播放
          video.play();
        });
      } else {
        video.play();
      }
    },
    getType(file) {
      let filename = file;
      let index1 = filename.lastIndexOf(".");
      let index2 = filename.length;
      let type = filename.substring(index1, index2);
      return type;
    },
  },
};
</script>

<style lang="scss" scoped>
.my-swipe {
  height: 500px;
}

.tabs ::v-deep .van-tab--active .van-tab__text {
  color: #000;
}
.tabs ::v-deep .van-tabs__line {
  background-color: #000;
}

.list {
  padding-bottom: 100px;
  background: #fff;
  .item {
    padding: 30px 24px;
    border-bottom: 1px dotted #f4f4f4;
    display: flex;
    align-items: center;
    .title {
      flex: 1;
      font-size: 32px;
      color: #222;
      line-height: 48px;
      margin-right: 20px;
      word-break: break-all;
    }

    .img-wrap {
      width: 300px;
      height: 200px;
      background: #f4f4f4;
      img {
        display: flex;
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.no-data {
  padding-top: 100px;
  height: 1000px;
  text-align: center;
  color: #666;
  font-size: 24px;
}

.video-wrap {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 1);
  z-index: 100;
  .video-source {
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .back {
    position: fixed;
    top: 40px;
    left: 20px;
    width: 80px;
    height: 80px;
    background: #000;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>